import * as React from "react";
import { css } from "@emotion/css";

import { Button, Page, Section, SubSection } from "../components";

export default function PolitiqueConfidentialite() {
  return (
    <Page
      tabTitle="Politique de confidentialité"
      pageTitle="Politique de confidentialité"
    >
      <Section column limited justify>
        <p>
          Chez KerNel Biomedical, nous sommes engagés dans la protection de vos
          données personnelles. Nous sommes donc transparents sur les modalités
          et les finalités du traitement de ces données, conformément à la loi
          du 6 janvier 1978 modifiée en 2004 et au Règlement Général sur la
          Protection des Données n°2016/679.
        </p>
        <p>
          Lorsque vous utilisez notre site internet, nous pouvons collecter des
          données à caractère personnel directes telles que votre nom, prénom,
          numéro de téléphone, adresse e-mail, nom de votre entreprise et
          fonction professionnelle, ainsi que des données indirectes telles que
          les cookies, votre adresse IP, votre navigateur ou votre système
          d'exploitation. En fournissant ces informations, vous acceptez que ces
          données à caractère personnel soient traitées.
        </p>
        <p>
          Nous nous réservons le droit de modifier notre Politique de protection
          des données à caractère personnel, notamment en cas de changement de
          nos procédures ou suite à l'évolution de la législation applicable. Si
          ces modifications sont importantes et ont un impact sur vos droits ou
          obligations en matière de protection de vos données personnelles, nous
          vous informerons de manière appropriée. Nous vous conseillons
          cependant de consulter régulièrement cette page pour prendre
          connaissance des mises à jour.
        </p>
      </Section>
      <Section title="Données personnelles collectées" column limited justify>
        <p>
          Dans le cadre de la souscription aux services Dotter.science et
          pendant la fourniture de ces services, KerNel Biomedical collecte les
          catégories de données suivantes&nbsp;:
        </p>
        <ul>
          <li>
            <strong>Données d'identification&nbsp;:</strong> nom, prénom,
            adresse postale et électronique, numéro de téléphone, numéro client,
            etc.
          </li>
          <li>
            <strong>Données de facturation et/ou de paiement&nbsp;:</strong>{" "}
            coordonnées bancaires, moyen de paiement, factures, etc.
          </li>
          <li>
            <strong>Données d'usage&nbsp;:</strong> services souscrits,
            participations aux études, communications, etc.
          </li>
          <li>
            <strong>Données de suivi de la relation client&nbsp;:</strong>{" "}
            demandes d'assistance, correspondance avec le client, etc.
          </li>
          <li>
            <strong>
              Données des professionnels intervenant dans la recherche&nbsp;:
            </strong>{" "}
            dans le cadre de la gestion d'études cliniques par Dotter.science,
            nous collectons des données sur les professionnels de santé
            intervenant dans la recherche. Ces données peuvent inclure des
            informations relatives à l'identité (nom, prénoms, sexe, coordonnées
            professionnelles postales, électroniques et téléphoniques,
            coordonnées bancaires), la formation et les diplômes, la vie
            professionnelle (notamment cursus professionnel, mode et type
            d'exercice, éléments nécessaires à l'évaluation des connaissances
            dont ils disposent pour réaliser la recherche), au numéro
            d'identification dans le répertoire partagé des professionnels de
            santé, au montant des indemnités et rémunérations perçues, la
            collaboration à d'autres études ainsi qu'à l'historique des accès et
            des connexions aux données médicales des personnes participant à une
            recherche.
          </li>
          <li>
            <strong>
              Autres données nécessaires à l'exécution du contrat&nbsp;:
            </strong>{" "}
            les données relatives à l'exécution du contrat, et particulièrement
            les données relatives aux études cliniques réalisées par le client,
            sont collectées et traitées conformément aux instructions des
            clients, responsables de traitement. KerNel Biomedical, via son
            service Dotter.science, n'agit qu'en tant que sous-traitant et n'est
            pas propriétaire de ces données. Cela inclut notamment les
            traitements relatifs aux données des personnes se prêtant à des
            recherches qui peuvent inclure des données sensibles (notamment des
            données de santé). Le client, responsable de traitement, doit
            s'assurer de la licéité de la collecte de ces données dans le cadre
            de son étude clinique.
          </li>
        </ul>
      </Section>
      <Section
        title="Base légale de traitement des données personnelles"
        column
        limited
        justify
      >
        <p>
          Nous sommes soucieux de la protection de vos données personnelles et
          ne les collectons que lorsque nous avons une justification légale pour
          le faire. Les données collectées par KerNel Biomedical dans le cadre
          de la fourniture de ses services sont collectées sur la base de
          différents fondements légaux&nbsp;:
        </p>
        <ul>
          <li>
            <strong>Consentement préalable&nbsp;:</strong> certaines données
            personnelles peuvent être collectées sur la base de votre
            consentement explicite préalable. Nous vous demandons votre
            autorisation de collecter ces données, par exemple lors de
            l'inscription à nos services. Vous avez le droit de retirer votre
            consentement à tout moment.
          </li>
          <li>
            <strong>
              Exécution d'un contrat ou mesures préalables à la conclusion d'un
              contrat&nbsp;:
            </strong>{" "}
            nous devons traiter certaines données personnelles nécessaires à
            l'exécution du contrat avec vous, telles que votre nom, votre
            adresse e-mail et vos informations de paiement. Cela inclut
            également les données traitées pour le compte du client responsable
            de traitement et sous sa responsabilité. En d'autres termes, nous
            collectons et traitons les données nécessaires pour vous fournir le
            service que vous avez demandé.
          </li>
          <li>
            <strong>Intérêt légitime de KerNel Biomedical&nbsp;:</strong> nous
            pouvons avoir un intérêt légitime à traiter vos données
            personnelles, par exemple pour garantir la sécurité et la
            confidentialité de nos services, pour détecter et prévenir les
            activités frauduleuses ou pour poursuivre nos intérêts commerciaux
            légitimes. Dans ce cas, nous prenons en compte vos droits et
            intérêts fondamentaux pour déterminer si le traitement est légitime
            et légal.
          </li>
          <li>
            <strong>Obligations légales&nbsp;:</strong> nous pouvons être amenés
            à traiter vos données personnelles pour respecter nos obligations
            légales, telles que répondre à une demande d'une autorité
            gouvernementale ou nous conformer à une réglementation applicable.
            En cas de litige, il peut être nécessaire de traiter vos données
            personnelles pour résoudre le différend.
          </li>
        </ul>
        <p>
          Il est important de souligner que les données collectées dans le cadre
          des études cliniques sont la propriété des clients, responsables de
          traitement. KerNel Biomedical, via son service Dotter.science, agit en
          tant que sous-traitant et ne peut traiter ces données que conformément
          aux instructions des clients. Nous mettons tout en œuvre pour garantir
          la sécurité et la confidentialité de ces données et respecter les
          obligations légales qui nous incombent en tant que sous-traitant. Les
          données traitées dans le cadre des études cliniques incluent notamment
          les données de santé des personnes participant aux études.
        </p>
      </Section>
      <Section
        title="Durée de conservation des données personnelles"
        column
        limited
        justify
      >
        <p>
          KerNel Biomedical s'engage à ne conserver vos données que pour la
          durée nécessaire à la réalisation des finalités pour lesquelles elles
          ont été collectées. Nous appliquons donc des durées de conservation
          spécifiques en fonction des finalités de traitement de vos données
          personnelles.
        </p>
        <p>
          Les données collectées à des fins commerciales, telles que les
          informations de contact ou les demandes de devis, sont conservées
          pendant une période maximale de trois (3) ans à compter de leur
          collecte.
        </p>
        <p>
          Dans certains cas, nous pouvons être tenus de conserver vos données
          personnelles pour une période plus longue, notamment lorsque cela est
          exigé par la loi ou lorsque cela est nécessaire pour protéger nos
          droits et intérêts légitimes. Dans tous les cas, nous veillons à ce
          que la durée de conservation soit proportionnée aux objectifs de
          traitement et aux obligations légales qui nous incombent.
        </p>
        <p>
          Nous avons également mis en place des mesures de sécurité techniques
          et organisationnelles pour garantir la confidentialité et la sécurité
          de vos données personnelles pendant leur conservation. Enfin, lorsque
          vos données personnelles ne sont plus nécessaires, nous les effaçons
          ou les rendons anonymes de manière sécurisée.
        </p>
      </Section>
      <Section
        title="Destinataires des données personnelles"
        column
        limited
        justify
      >
        <p>
          Les données à caractère personnel collectées par KerNel Biomedical
          sont destinées exclusivement à l'exécution des prestations de service
          proposées. Elles sont traitées par KerNel Biomedical et ses
          sous-traitants, qui ont tous signé des contrats de sous-traitance
          incluant des obligations de confidentialité et de sécurité. Aucune
          donnée personnelle n'est communiquée à des tiers non autorisés.
        </p>
        <p>
          Les données à caractère personnel peuvent également être transférées
          aux personnes et autorités dont le droit d'accès aux données à
          caractère personnel est reconnu par la loi, les règlements ou les
          dispositions émanant d'autorités habilitées par la loi, telles que les
          autorités judiciaires et administratives.
        </p>
        <p>
          Il est important de souligner que les données collectées dans le cadre
          des études cliniques sur Dotter.science sont la propriété des clients,
          responsables de traitement. KerNel Biomedical, via son service
          Dotter.science, agit en tant que sous-traitant et ne peut transférer
          ces données qu'en conformité avec les instructions des clients. Nous
          mettons tout en œuvre pour garantir la sécurité et la confidentialité
          de ces données et respecter les obligations légales qui nous incombent
          en tant que sous-traitant.
        </p>
      </Section>
      <Section title="Sécurité des données personnelles" column limited justify>
        <p>
          Chez KerNel Biomedical, nous accordons une grande importance à la
          sécurité et à la protection de vos données personnelles. Nous mettons
          en place des mesures administratives, techniques et physiques pour
          prévenir tout accès, modification, perte ou divulgation non-autorisés
          de vos données, en fonction de leur niveau de sensibilité. Nous
          sélectionnons également avec soin nos partenaires et prestataires qui
          pourraient être amenés à accéder à vos données, afin de garantir
          qu'ils respectent des normes de sécurité et de confidentialité
          similaires aux nôtres. Les données sont traitées de façon électronique
          et/ou manuelle avec des mesures de sécurité appropriées pour assurer
          leur protection et leur confidentialité.
        </p>
      </Section>
      <Section title="Droits des utilisateurs" column limited justify>
        <p>
          Le Règlement (UE) 2016/679 du Parlement européen et du Conseil,
          également appelé Règlement général sur la protection des données
          (RGPD), vous confère plusieurs droits relatifs à vos données
          personnelles. Son objectif est de protéger les personnes physiques en
          ce qui concerne le traitement de leurs données à caractère personnel
          et la libre circulation de ces données.
        </p>
        <p>
          Vous avez le droit de demander l'accès à vos données personnelles pour
          savoir quelles données nous avons collectées vous concernant. Si vous
          constatez que ces données sont inexactes ou incomplètes, vous pouvez
          exercer votre droit de rectification pour les corriger. Vous pouvez
          également demander la suppression de vos données à caractère
          personnel, sauf si nous avons une obligation légale de les conserver.
        </p>
        <p>
          Si vous souhaitez limiter le traitement de vos données, vous pouvez
          exercer votre droit à la limitation du traitement. Vous avez également
          le droit à la portabilité de vos données personnelles pour les
          récupérer et les transférer à un autre responsable de traitement.
        </p>
        <p>
          Vous pouvez vous opposer au traitement de vos données personnelles,
          sauf si nous avons des motifs légitimes pour poursuivre ce traitement.
          En cas de non-respect de vos droits, vous avez le droit de déposer une
          réclamation auprès de l'autorité de contrôle compétente. En France, il
          s'agit de la CNIL et vous pouvez trouver plus d'informations sur vos
          droits sur leur site web&nbsp;:{" "}
          <a href="http://www.cnil.fr" target="_blank">
            www.cnil.fr
          </a>
          .
        </p>
        <p>
          Si vous avez des questions ou des préoccupations concernant vos
          données personnelles, vous pouvez contacter notre délégué à la
          protection des données à l'adresse email suivante&nbsp;:{" "}
          <a href="mailto:dpo@kernelbiomedical.com">dpo@kernelbiomedical.com</a>
          .
        </p>
      </Section>
    </Page>
  );
}
